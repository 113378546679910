<template>
	<v-container fluid style="max-width: 1600px">
		<div class="d-flex align-center">
			<v-icon class="mx-4"> mdi-magnify </v-icon>
			<v-text-field v-model="title" :placeholder="`${$t('search.search')}: ${$t('auth.name')}, ${$t('companies.location')}`" />
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4"> mdi-filter </v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4"> mdi-close </v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.thesisOptions"
									:label="$t('offers.thesisOption')"
									:items="availableThesisOptions"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.offerTypes"
									:label="$t('offers.offerType')"
									:items="availableOfferTypes"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-text-field v-model="filters.location" :label="$t('companies.location')" outlined rounded hide-details="auto" class="pb-0" />
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.fullParttime"
									:label="$t('offers.fullParttime')"
									:items="fullParttimeOptions"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.sectors"
									:label="$t('companies.sectors')"
									:items="availableSectors"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									clearable
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loadingOffer">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="4" lg="6" md="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="offers.length">
				<v-row key="filteresResults" no-gutters>
					<v-col cols="12" xl="4" lg="6" md="12" v-for="offer in filteredOffers" :key="`offer${offer.id}`" class="px-1">
						<OfferSearchCard :offer="offer" />
					</v-col>
				</v-row>

				<template v-if="!filteredOffers.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('offers.offers'),
		}
	},
	data() {
		return {
			filtersEditMode: false,
			title: '',
			fullParttimeOptions: [
				{ key: 0, name: this.$t('offers.parttime') },
				{ key: 1, name: this.$t('offers.fulltime') },
			],
			filters: {
				fullParttime: [],
				thesisOptions: [],
				offerTypes: [],
				companies: [],
				sectors: [],
				location: '',
			},
		}
	},
	components: {
		OfferSearchCard: () => import('@/components/offers/OfferSearchCard.vue'),
	},
	watch: {
		company(val) {
			if (val) {
				this.setLoadingOffer(true)
				this.fetchOffersCompany({ companyID: val.id }).then(() => {
					this.setLoadingOffer(false)
				})
			}
		},
	},
	computed: {
		filteredOffers() {
			return this.offers.filter(
				(offer) =>
					(!this.filters.thesisOptions.length || offer.info.thesisOptions.some((element) => this.thesisOptions_key.includes(element.key))) &&
					(!this.filters.offerTypes.length || this.offerTypes_key.includes(offer.info.type.key)) &&
					(!this.filters.fullParttime.length || this.fullParttime_key.includes(offer.info.time.key)) &&
					(!this.filters.sectors.length || offer.info.sectors.some((element) => this.sectors_id.includes(element.id))) &&
					(removeAccents(offer.info.location.city).toLowerCase().includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(offer.info.location.province).toLowerCase().includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(offer.info.location.country.name).toLowerCase().includes(removeAccents(this.filters.location).toLowerCase())) &&
					(removeAccents(offer.title).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.city).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.province).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.country.name).toLowerCase().includes(removeAccents(this.title).toLowerCase()))
			)
		},
		thesisOptions_key() {
			return this.filters.thesisOptions.map((element) => element.key)
		},
		offerTypes_key() {
			return this.filters.offerTypes.map((element) => element.key)
		},
		fullParttime_key() {
			return this.filters.fullParttime.map((element) => element.key)
		},
		sectors_id() {
			return this.filters.sectors.map((element) => element.id)
		},
		...mapGetters({
			company: 'companies/company',
			offers: 'offers/offers',
			loadingOffer: 'offers/loading',
			availableOfferTypes: 'data/availableOfferTypes',
			availableThesisOptions: 'data/availableThesisOptions',
			availableSectors: 'cv/availableSectors',
		}),
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.fetchAvailableThesisOptions()
		this.fetchAvailableSectors({ keywords: '' })
		if (this.company?.id) {
			this.setLoadingOffer(true)
			this.fetchOffersCompany({ companyID: this.company.id }).then(() => {
				this.setLoadingOffer(false)
			})
		}
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		deselectSector(item) {
			this.filters.sectors = this.filters.sectors.filter((i) => i != item)
		},
		...mapActions('offers', ['setLoadingOffer', 'fetchOffersCompany']),
		...mapActions('cv', ['fetchAvailableSectors']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableThesisOptions']),
	},
}
</script>
